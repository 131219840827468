import { template as template_58e0cb0ab8f84091a31613163bb99bba } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_58e0cb0ab8f84091a31613163bb99bba(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
