import { template as template_0cad9eb80f0b4da4a6b61ee782f64a6c } from "@ember/template-compiler";
const FKLabel = template_0cad9eb80f0b4da4a6b61ee782f64a6c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
